<template>
  <el-row class="displays">
    <el-col :span="24">
      <div class="pages hidden-xs-only">
        <div class="banner">
          <img src="@/assets/images/parallelMeetings/banner.png" alt="" />
          <div class="title" :class="{ weibei: lang }">
            {{ $t("poster").b }}
          </div>
        </div>
        <div class="conterList">
          <div class="left box" ref="xqBox">
            <div
              class="item"
              :class="index == type ? 'select' : ''"
              v-for="(item, index) in $t('poster').list"
              :key="index"
              @click="setType(index)"
            >
              {{ item }}
            </div>
          </div>
          <div class="right">
            <viewer
              :images="
                require('@/assets/images/poster/' + (type - 0 + 1) + '.png')
              "
            >
              <img
                :src="
                  require('@/assets/images/poster/' + (type - 0 + 1) + '.png')
                "
                alt=""
              />
            </viewer>
            <!-- <img :src= alt="" /> -->
          </div>
        </div>
      </div>
      <div class="pages1 hidden-sm-and-up">
        <div class="banner">
          <img src="@/assets/images/parallelMeetings/banner.png" alt="" />
          <div class="title" :class="{ weibei: lang }">
            {{ $t("poster").b }}
          </div>
        </div>
        <div class="conterList">
          <div class="left">
            <div
              class="item"
              :class="index == type ? 'select' : ''"
              v-for="(item, index) in $t('poster').list"
              :key="index"
              @click="setType(index)"
            >
              {{ item }}
            </div>
          </div>
          <div class="right">
            <el-image
              :src="
                require('@/assets/images/poster/' + (type - 0 + 1) + '.png')
              "
            >
            </el-image>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      type: 0,
    };
  },
  watch: {},
  mounted() {
    if (sessionStorage.getItem("poType")) {
      this.type = sessionStorage.getItem("poType");
    }
    this.$nextTick(() => {
      this.xqBox.addEventListener("scroll", this.handleScroll);
      if (sessionStorage.getItem("number")) {
        this.scrollTo(sessionStorage.getItem("number"));
      }
    });
  },
  computed: {
    xqBox() {
      return this.$refs.xqBox;
    },
    lang() {
      const localeLang = window.localStorage.getItem("locale") || "zh-CN";
      return localeLang === "zh-CN";
    },
  },
  methods: {
    handleScroll() {
      //获取dom滚动距离
      const scrollTop = this.xqBox.scrollTop;
      sessionStorage.setItem("number", scrollTop);
      console.log(scrollTop);
    },
    scrollTo(i) {
      this.$nextTick(() => {
        let Box = this.$el.querySelector(".box");
        Box.scrollTop = i;
      });
    },
    setType(index) {
      this.type = index;
      sessionStorage.setItem("poType", index);
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
<style lang="stylus" scoped>
.pages {
  width: 100%;

  /* 滚动条样式 */
  ::-webkit-scrollbar {
    cursor: pointer;
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #000000;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #9F9F9F;
  }

  .banner {
    width: 100%;
    background: #405E3C;
    position: relative;

    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -170px;
      margin-top: -40px;
      width: 340px;
      height: 80px;
      background: rgba(141, 198, 63, 0.9);
      text-align: center;
      line-height: 80px;
      font-weight: 700;
      font-size: 42px;
      color: #FFFFFF;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }

  .conterList {
    max-width: 1400px;
    height: 1410px;
    margin: auto;
    display: flex;
    padding: 50px 0;

    .left {
      width: 275px;
      height: 100%;
      overflow-y: auto;
      background: #000000;
      padding: 20px;
      box-sizing: border-box;

      .item {
        width: 100%;
        min-height: 100px;
        background: #405E3C;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #FFFFFF;
        padding: 0 10px;
        box-sizing: border-box;
        cursor: pointer;
        margin-bottom: 4px;
      }

      .select {
        border: 4px solid #A4D85D;
      }
    }

    .right {
      flex: 1;
      background: #262B26;
      padding: 25px 80px;
      box-sizing: border-box;

      img {
        width: 100%;
      }
    }
  }
}

.pages1 {
  width: 100%;

  /* 滚动条样式 */
  ::-webkit-scrollbar {
    cursor: pointer;
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #000000;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #9F9F9F;
  }

  .banner {
    width: 100%;
    height: 190px;
    background: #405E3C;
    position: relative;

    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -60px;
      margin-top: -25px;
      width: 120px;
      height: 50px;
      background: rgba(141, 198, 63, 0.9);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 16px;
      color: #FFFFFF;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .conterList {
    width: 100%;
    margin: auto;
    padding: 20px 0;

    .left {
      width: 100%;
      height: 100px;
      overflow-x: auto;
      background: #000000;
      padding: 5px 0 0;
      box-sizing: border-box;
      display: flex;

      .item {
        min-width: 200px;
        height: 100%;
        margin-right: 5px;
        background: #405E3C;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #FFFFFF;
        padding: 0 10px;
        box-sizing: border-box;
        cursor: pointer;
        margin-bottom: 4px;
      }

      .select {
        border: 4px solid #A4D85D;
      }
    }

    .right {
      width: 100%;
      background: #262B26;
      padding: 10px 10px;
      box-sizing: border-box;

      img {
        width: 100%;
      }
    }
  }
}
</style>